import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'

import Layout from '../components/layout'
import SEO from '../components/seo'

import PageHeader from '../components/PageHeader'

export default class AboutUsPage extends PureComponent {
  state = {
    currentMobileTab: -1,
  }

  onTabToggle = index => {
    this.setState(({ currentMobileTab }) => ({
      currentMobileTab: currentMobileTab === index ? -1 : index,
    }))
  }

  render() {
    const { careersPage: page, jobs } = this.props.pageContext
    return (
      <Layout>
        <SEO title={page.title} keywords={[`longevity`]} />

        <PageHeader titles={[page.title]} richDescription={page.description} />

        <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto mt-16 mb-24 lg:mb-32 font-serif text-blue-dianne">
          <div className="mt-32-TEMP mt-16 px-8 lg:px-0">
            <h2 className="text-2xl font-normal leading-normal">
              Our Open Positions:
            </h2>
            {[{ name: 'Uncategorized', positions: jobs }].map(
              (division, divisionIndex) => (
                <div className="mt-8" key={divisionIndex}>
                  <div className="flex items-center justify-between"></div>
                  <div className={classnames('mt-6 lg:flex lg:flex-wrap', {})}>
                    {division.positions.map((position, positionIndex) => (
                      <div key={positionIndex} className="lg:w-1/4 mb-8">
                        <p className="font-sans leading-normal text-lg">
                          {position.title}
                        </p>
                        <Link
                          to={`/apply/${position._meta.uid}`}
                          className="inline-block mt-4 font-sans text-blue-dianne text-sm uppercase no-underline font-bold"
                        >
                          Apply
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </Layout>
    )
  }
}
